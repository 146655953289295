/* Color values */
:root {
    --color-1: hsla(337, 84%, 48%, 0.75);
    --color-2: hsla(160, 50%, 48%, 0.75);
    --color-3: hsla(190, 61%, 65%, 0.75);
    --color-4: hsla(41, 82%, 52%, 0.75);
}

/* Constants */
.loader {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    transform: rotate(165deg);
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
}

.loader::before {
    animation: before 2s infinite;
}

.loader::after {
    animation: after 2s infinite;
}

/* Keyframes for ::before */
@keyframes before {
    0% {
        width: 0.5em;
        box-shadow:
                1.0em -0.75em var(--color-1),
                -1.0em 0.75em var(--color-3);
    }
    35% {
        width: 2.5em;
        box-shadow:
                0 -0.75em var(--color-1),
                0 0.75em var(--color-3);
    }
    70% {
        width: 0.5em;
        box-shadow:
                -1.0em -0.75em var(--color-1),
                1.0em 0.75em var(--color-3);
    }
    100% {
        box-shadow:
                1.0em -0.75em var(--color-1),
                -1.0em 0.75em var(--color-3);
    }
}

/* Keyframes for ::after */
@keyframes after {
    0% {
        height: 0.5em;
        box-shadow:
                0.75em 1.0em var(--color-2),
                -0.75em -1.0em var(--color-4);
    }
    35% {
        height: 2.5em;
        box-shadow:
                0.75em 0 var(--color-2),
                -0.75em 0 var(--color-4);
    }
    70% {
        height: 0.5em;
        box-shadow:
                0.75em -1.0em var(--color-2),
                -0.75em 1.0em var(--color-4);
    }
    100% {
        box-shadow:
                0.75em 1.0em var(--color-2),
                -0.75em -1.0em var(--color-4);
    }
}

.loader {
    position: absolute;
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);
}
