ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: center;

}

li {
    display: inline-block;
    position: relative;
}

li a {
    display: block;
    color: #212529;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: black;
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
}

li a:hover:not(.active)::after {
    width: 100%;
    left: 0;
}

li a.active {
    color: white;
    background-color: #4CAF50;
}
