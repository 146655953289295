.button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 10px 27px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.info {background-color: #008CBA;} /* Blue */
.error {background-color: #f44336;} /* Red */
.gray {background-color: #e7e7e7; color: black;} /* Gray */
.black {background-color: #555555;} /* Black */