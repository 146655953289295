.card {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    word-wrap: break-word; /* Для старых браузеров */
    overflow-wrap: break-word; /* Современный стандарт */
}

pre {
    white-space: pre-wrap; /* Сохраняет пробелы, но переносит длинные строки */
    word-wrap: break-word; /* Переносит слова, если они слишком длинные */
}

.row:after {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 60px;
}